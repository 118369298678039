define('slate-payroll/components/x-existence-check', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    attributeBindings: ['title'],

    _queryPoint: Ember.computed.reads('queryPoint'),
    _key: Ember.computed.reads('key'),
    _modelKey: Ember.computed.reads('modelKey'),
    _proposed: Ember.computed.reads('proposed'),
    _existenceSpecific: Ember.computed.reads('existenceSpecificEndpoint'),

    init: function init() {
      this._super.apply(this, arguments);
      (true && !(this.get('_queryPoint')) && Ember.assert('You must add a queryPoint attr to the x-existence-check component.', this.get('_queryPoint')));
    },


    title: Ember.computed('exists', 'didCheckExistence', 'working', function () {
      if (this.get('working')) {
        return 'Loading...';
      } else if (this.get('didCheckExistence')) {
        return this.get('exists') ? 'Already exists.' : 'Good to go!';
      }
    }),

    _existenceCheck: function _existenceCheck() {
      var _this = this;

      var existenceSpecific = this.get('_existenceSpecific'),
          proposed = this.get('_proposed'),
          key = this.get('_key'),
          queryPoint = this.get('_queryPoint');

      this.set('_checkEvent', null);

      var url = queryPoint,
          query = {};

      this.ajaxStart();
      this.get('onLoading')(true);

      if (existenceSpecific) {
        url += '/' + proposed;
      } else {
        (true && !(!!key) && Ember.assert('x-existence-check must have a key for non-existence-specific query points.', !!key));

        query[key] = proposed;
      }

      Ember.$.getJSON(url, query).then(Ember.run.bind(this, function (res) {
        _this.ajaxSuccess();
        _this.get('onLoading')(false);

        _this.setProperties({
          didCheckExistence: true,
          exists: existenceSpecific ? res.exists : !!res[_this.get('_modelKey')].length
        });

        _this.send('change', _this.get('exists'));
      })).fail(function (err) {
        _this.ajaxError(err);
        _this.get('onLoading')(false);
      });
    },


    _shouldQueueCheck: Ember.observer('_proposed', function () {
      this.set('didCheckExistence', false);

      if (this.get('_checkEvent')) {
        Ember.run.cancel(this.get('_checkEvent'));
      }

      this.set('exists', undefined);
      this.send('change', undefined);

      var scheduled = Ember.run.later(this, this._existenceCheck, 800);
      this.setProperties({ _checkEvent: scheduled });
    }),

    actions: {
      change: function change(exists) {
        this.get('action')(exists);
      }
    }
  });
});