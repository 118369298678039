define('slate-payroll/components/x-chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['chart-view'],

    height: '400',
    chartOptions: {},
    dataOptions: {},

    didInsertElement: function didInsertElement() {
      this._draw();
    },


    _draw: Ember.observer('dataset.[]', function () {
      this.set('isLoadingChart', true);

      Ember.run.next(this, function () {
        var chart = this.get('chart'),
            data = Ember.$.extend({ columns: this.get('dataset') }, this.get('dataOptions'));

        (true && !(Ember.isArray(data.columns)) && Ember.assert('Dataset provided to Component#general-chart#_draw should be an array', Ember.isArray(data.columns)));


        if (!chart) {
          chart = window.c3.generate(Ember.$.extend({
            bindto: '#' + this.get('elementId'),
            data: data,
            size: { height: this.get('height') }
          }, this.get('chartOptions')));
        } else {
          chart.load(data);
        }

        this.setProperties({
          isLoadingChart: false,
          chart: chart
        });
      });
    })
  });
});