define('slate-payroll/mixins/route-abstractions/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    segmentKey: 'id',

    model: function model(params) {
      var modelName = this.get('modelName');

      (true && !(modelName) && Ember.assert('You must specify a modelName.', modelName));


      return this.store.find(modelName, params[this.segmentKey]);
    },


    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);

        var model = this.controller.get('model'),
            hasChangedAttributes = Object.keys(model.changedAttributes()).length > 0;

        if (hasChangedAttributes && !confirm('Are you sure you want to abandon progress on this page?')) {
          transition.abort();
        } else {
          if (hasChangedAttributes) {
            model.rollbackAttributes();
          }

          return true;
        }
      }
    }
  });
});